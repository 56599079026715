import React, { useState } from 'react'
// import IdCheckerHero from '../../assets/id-checker-hero.png'
import digital from '../../assets/id-checker/digital.png'
import banking from '../../assets/id-checker/banking.png'
import document from '../../assets/id-checker/document.png'
// import widgetImg from '../../assets/id-checker/widget-img.png'
// import Newsletter from '../../components/newsletter'
import Blogcard from '../../components/blog-card'
import ReactElasticCarousel from 'react-elastic-carousel'
import Partners from '../../components/partners'


export default function IdVerification() {
    const [moreGraphPage, setMoreGraphPage] = useState(1)
    return (
        <div>
            <section className="id-checker-hero py-5">
                <div className="container py-5">
                    <div className="row align-items-center">
                        <div className="col-lg-7 py-5">
                            <h1>Identity Verification</h1>
                            <p>
                                Offers a quick and accurate solution to verify users' or 
                                businesses' identities across Africa using data directly 
                                from issued identifiers and AI-powered biometrics.
                            </p>
                            <button className="btn btn-white px-5 py-3 mt-3">Talk to Sales</button>
                        </div>
                    </div>
                </div>
            </section>
            
            <section className="id-checker-channels py-md-5">
                <div className="container">
                    <div className="col-md-6 mx-auto text-md-center py-5">
                        <p>
                            Get all the juice in few steps
                        </p>
                        <h2>Subset of Products</h2>
                    </div>

                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <img src={digital} alt="" />
                        </div>
                        <div className="col-md-6">
                            <div className="col-md-12 col-lg-10 my-4 mx-auto ">
                                <h3> Data Lookup/ Verification</h3>
                                <p className="my-4">
                                    Identitypass verification API ascertains hundreds of identity data 
                                    fields from different African countries, using built-in intelligence 
                                    and mapping. With fewer and automated processes involved, your 
                                    business can scale higher more quickly and smoothly.
                                </p>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="d-flex align-items-center">
                                            <p className="tick me-2">
                                                <i className="ri-check-line"></i>
                                            </p>
                                            <p> Optimize acceptance rates</p>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <p className="tick me-2">
                                                <i className="ri-check-line"></i>
                                            </p>
                                            <p>Gain valuable insights</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="d-flex align-items-center">
                                            <p class="tick me-2">
                                                <i class="ri-check-line"></i>
                                            </p>
                                            <p>Track historical transactions</p>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <p class="tick me-2">
                                                <i class="ri-check-line"></i>
                                            </p>
                                            <p>Safeguard customers' privacy</p>
                                        </div>
                                        {/* <div class="d-flex align-items-center">
                                            <p class="tick me-2">
                                                <i class="ri-check-line"></i>
                                            </p>
                                            <p>RC Number</p>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <p class="tick me-2">
                                                <i class="ri-check-line"></i>
                                            </p>
                                            <p>NIN</p>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <p class="tick me-2">
                                                <i class="ri-check-line"></i>
                                            </p>
                                            <p>TIN</p>
                                        </div> */}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-md-6 order-md-2">
                            <img src={banking} alt="" />
                        </div>
                        <div className="col-md-6 order-md-1">
                            <div className="col-md-12 col-lg-10 my-4 mx-auto">
                                <h3> Document Verification</h3>
                                <p className="my-4">
                                    Authenticate users with any of their identifiers. 
                                    You can verify your customers using any of our provided 
                                    verification endpoints for these Identifiers. 
                                </p>
                                <div class="row">
                                    <div class="col-md-5">
                                        <div class="d-flex align-items-center">
                                            <p class="tick me-2">
                                                <i class="ri-check-line"></i>
                                            </p>
                                            <p>Authenticate</p>
                                        </div>
                                        {/* <div class="d-flex align-items-center">
                                            <p class="tick me-2">
                                                <i class="ri-check-line"></i>
                                            </p>
                                            <p> NUBAN</p>
                                        </div> */}
                                    </div>
                                    <div class="col-md-7">
                                        <div class="d-flex align-items-center">
                                            <p class="tick me-2">
                                                <i class="ri-check-line"></i>
                                            </p>
                                            <p>Compare against user image</p>
                                        </div>
                                        {/* <div class="d-flex align-items-center">
                                            <p class="tick me-2">
                                                <i class="ri-check-line"></i>
                                            </p>
                                            <p>BVN</p>
                                        </div> */}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <img src={document} alt="" />
                        </div>
                        <div className="col-md-6">
                            <div className="col-md-12 col-lg-10 my-4 mx-auto">
                                <h3>Biometrics Authentication (Enrol and Authenticate users using biological traits)</h3>
                                <p className="my-4">
                                    We provide an exceptional identity verification framework 
                                    to sustain your growth. Use Identitypass SDKs and APIs to fulfill 
                                    KYC requirements and stop fraud. 
                                </p>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="d-flex align-items-center">
                                            <p class="tick me-2">
                                                <i class="ri-check-line"></i>
                                            </p>
                                            <p> Face Enrollment</p>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <p class="tick me-2">
                                                <i class="ri-check-line"></i>
                                            </p>
                                            <p>Fingerprint</p>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="d-flex align-items-center">
                                            <p class="tick me-2">
                                                <i class="ri-check-line"></i>
                                            </p>
                                            <p>Face Match</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="more-identity-graph-area py-5" style={{backgroundColor:"#F7EDFC"}} >
                <div className="container">
                    <div className="col-md-6 py-5">
                        {/* <h2>Identity Checker Widget</h2>
                        <p>The Businesses using our digital checkout product have the ability to do the following:</p> */}
                    </div>

                    <div className="row ">
                        <div className="col-md-6">
                            <h5>GET THE GIST</h5>
                            <h2>Seamless Integration</h2>
                            <p>
                                Identitypass gives you the flexibility to design the identity 
                                verification workflow that best meets your business specifications. 
                                Irrespective of your industry, use case, or customer- base, we've got you covered.
                            </p>
                        </div>
                            
                        <div className="col-md-6 ps-lg-5">
                            {moreGraphPage === 1 ? 
                                <>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="tick me-3">
                                                <i className="ri-check-line"></i>
                                            </div>
                                            <div>
                                                <h5>Get the ball rolling with our SDK</h5>
                                                <p>
                                                    Add an extra layer of verification to your website 
                                                    through our SDKs. Confirm your customers are truly who 
                                                    they claim to be using Identity verification solutions 
                                                    integrated into your existing verification system/tech 
                                                    stack with just a few steps. This keeps fraudsters out 
                                                    while real customers are onboarded in seconds.
                                                </p>
                                                <a href="https://dashboard.myidentitypass.com/signUp" target="_blank" rel="noreferrer noopener" > Get Started</a>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='inactive d-flex align-items-center px-md-4 pt-5' onClick={()=> setMoreGraphPage(2)} >
                                        <i className="ri-pulse-line me-3" />
                                        <h5>Integrate with our APIs</h5>
                                    </div><hr />
                                    <div className='inactive d-flex align-items-center p-4' onClick={()=> setMoreGraphPage(3)}>
                                        <i className="ri-qr-scan-2-line me-3"/>
                                        <h5>Not a Dev? Enjoy our No-Code Integrations</h5>
                                    </div><hr />
                                    <div className='inactive d-flex align-items-center p-4' onClick={()=> setMoreGraphPage(4)}>
                                        <i className="ri-alert-line me-3"/>
                                        <h5>Play with our Portal Directly</h5>
                                    </div><hr />
                                </>
                                :
                                moreGraphPage === 2 ? 
                                <>
                                    <div className='inactive d-flex align-items-center px-4 pt-5' onClick={()=>setMoreGraphPage(1)} >
                                        <i className="ri-checkbox-circle-line ri-xl me-3" />
                                        <h5>Get the ball rolling with our SDK</h5>
                                    </div><hr />
                                    <div className="card">
                                        <div className="card-body">
                                            <i class="ri-pulse-line ri-xl me-3 mt-2" style={{color:"#1633A3"}} />
                                            <div>
                                                <h5>Integrate with our APIs</h5>
                                                <p>
                                                    You can take productivity and automation to the next level with any of our APIs
                                                </p>
                                                <a href="https://dashboard.myidentitypass.com/signUp" target="_blank" rel="noreferrer noopener" > Get Started</a>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='inactive d-flex align-items-center p-4' onClick={()=> setMoreGraphPage(3)}>
                                        <i className="ri-qr-scan-2-line me-3"/>
                                        <h5>Not a Dev? Enjoy our No-Code Integrations</h5>
                                    </div><hr />
                                    <div className='inactive d-flex align-items-center p-4' onClick={()=> setMoreGraphPage(4)}>
                                        <i className="ri-alert-line me-3"/>
                                        <h5>Play with our Portal Directly</h5>
                                    </div><hr />
                                </>
                                :
                                moreGraphPage === 3 ? 
                                <>
                                    
                                    <div className='inactive d-flex align-items-center px-4 pt-5' onClick={()=>setMoreGraphPage(1)} >
                                        <i className="ri-checkbox-circle-line ri-xl me-3" />
                                        <h5>Get the ball rolling with our SDK</h5>
                                    </div><hr />

                                    <div className='inactive d-flex align-items-center px-md-4 pt-5' onClick={()=> setMoreGraphPage(2)} >
                                        <i className="ri-pulse-line me-3" />
                                        <h5>Integrate with our APIs</h5>
                                    </div><hr />
                                    
                                    <div className="card">
                                        <div className="card-body">
                                            <i class="ri-qr-scan-2-line ri-xl me-3 mt-2" style={{color:"#1633A3"}} />
                                            <div>
                                                <h5>Not a Dev? Enjoy our No-Code Integrations</h5>
                                                <p>
                                                    Create a customized Workflow that replicates your brand identity and 
                                                    blends with your verification needs through the identity checker widget.
                                                </p>
                                                <a href="https://dashboard.myidentitypass.com/signUp" target="_blank" rel="noreferrer noopener" > Get Started</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='inactive d-flex align-items-center p-4' onClick={()=> setMoreGraphPage(4)}>
                                        <i className="ri-alert-line me-3"/>
                                        <h5>Play with our Portal Directly</h5>
                                    </div><hr />
                                </>
                                :
                                <>
                                    <div className='inactive d-flex align-items-center px-4 pt-5' onClick={()=>setMoreGraphPage(1)} >
                                        <i className="ri-checkbox-circle-line ri-xl me-3" />
                                        <h5>Get the ball rolling with our SDK</h5>
                                    </div><hr />
                                    <div className='inactive d-flex align-items-center px-md-4 pt-5' onClick={()=> setMoreGraphPage(2)} >
                                        <i className="ri-pulse-line me-3" />
                                        <h5>Integrate with our APIs</h5>
                                    </div><hr />
                                    <div className='inactive d-flex align-items-center p-4' onClick={()=> setMoreGraphPage(3)}>
                                        <i className="ri-qr-scan-2-line me-3"/>
                                        <h5>Not a Dev? Enjoy our No-Code Integrations</h5>
                                    </div><hr />
                                    <div className="card">
                                        <div className="card-body">
                                            <i class="ri-alert-line ri-xl me-3 mt-2" style={{color:"#1633A3"}} />
                                            <div>
                                                <h5>Play with our Portal Directly</h5>
                                                <p>
                                                    Explore all identitypass's functionalities and products with our 
                                                    sandbox environment to determine the best verification product that 
                                                    best fits your business needs 
                                                </p>
                                                <a href="https://dashboard.myidentitypass.com/signUp" target="_blank" rel="noreferrer noopener" > Get Started</a>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                        {/* <div className="col-6 col-md-4 col-lg-2">
                            <i className="ri-code-s-slash-line" />
                            <h5>Verification Channel</h5>
                            <p>This gives you the ability to enable or disable Verification Channel</p>
                        </div>
                        <div className="col-6 col-md-4 col-lg-2">
                            <i className="ri-creative-commons-nd-line" />
                            <h5>Verification Country</h5>
                            <p>Enable or disable country from the list of supported countries</p>
                        </div>
                        <div className="col-6 col-md-4 col-lg-2">
                            <i className="ri-settings-2-line" />
                            <h5>Customize Checkout</h5>
                            <p>Here you get to customize digital identity checkout colors and text </p>
                        </div>
                        <div className="col-6 col-md-4 col-lg-2">
                            <i className="ri-bubble-chart-line" />
                            <h5>Verification Data</h5>
                            <p>View all verification data with a detailed status, including the abandoned checkout</p>
                        </div>
                        <div className="col-6 col-md-4 col-lg-2">
                            <i className="ri-stack-line" />
                            <h5>Detailed Transactions</h5>
                            <p>We so much flexibilty to view all transactions billing details that applies</p>
                        </div> */}
                    </div>
                    {/* <div className="col-md-9 mx-auto mt-5">
                        <img src={widgetImg} alt="" className="w-100" />
                    </div> */}
                </div>
            </section>

            <section className="ideal-customers every-industry-area py-5">
                <div className="container">
                    <div className="col-md-6 mx-auto text-center py-5">
                        <h2>Ideal Customers</h2>
                        <p>From Local to Global Organisations, all you have to do is integrate our APIs into your System.</p>
                    </div>
                    <div className="row d-none d-lg-flex">
                        <div className="col-md-3 mt-5">
                            <div className="card ">
                                <div className="card-body">
                                    <i className="ri-bank-line"/>
                                    <h1>Crypto <br /> Companies</h1>
                                    <p>
                                        Our Biometric verification, crypto exchanges can get easy, 
                                        secure and law compliant customers’ verification. We combine 
                                        AI-based technology with the expertise of highly qualified 
                                        fraud-spotting specialists
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mt-5">
                            <div className="card ">
                                <div className="card-body">
                                    <i className="ri-wallet-line"/>
                                    <h1>Fintechs & <br /> banks</h1>
                                    <p>
                                        Our layered approach using the identity document combined 
                                        with facial biometrics offers a more secure way to verify 
                                        identities of applicants and customers for digital financial 
                                        companies. 
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mt-5">
                            <div className="card">
                                <div className="card-body">
                                    <i className="ri-layout-masonry-line"/>
                                    <h1>Schools & <br /> Aviations</h1>
                                    <p>
                                        Identitypass’s verification solutions ensure you’re dealing 
                                        with only the right individuals and eases the verification 
                                        processes providing accurate data. 
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mt-5">
                            <div className="card">
                                <div className="card-body">
                                    <i className="ri-layout-masonry-line"/>
                                    <h1 style={{fontSize:"1.9rem"}}>E-commerce & <br /> Platforms</h1>
                                    <p>
                                        Our verification platform provides flexible & easy access to a 
                                        wide range of KYC products and services such as Biometrics verification 
                                        and documents oriented verifications ensuring customers’ privacy protection. 
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* displays only on tablet  */}
                    <div className="d-none d-md-block d-lg-none">
                        <ReactElasticCarousel itemsToShow={2.0} enableAutoplay={true} >
                            <div className="col me-4 my-4">
                                <div className="card ">
                                    <div className="card-body">
                                        <i className="ri-bank-line"/>
                                        <h1>Crypto <br /> Companies</h1>
                                        <p>
                                            Our Biometric verification, crypto exchanges can get easy, 
                                            secure and law compliant customers’ verification. We combine 
                                            AI-based technology with the expertise of highly qualified 
                                            fraud-spotting specialists
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col me-4 my-4">
                                <div className="card ">
                                    <div className="card-body">
                                        <i className="ri-wallet-line"/>
                                        <h1>Fintechs & <br /> banks</h1>
                                        <p>
                                            Our layered approach using the identity document combined 
                                            with facial biometrics offers a more secure way to verify 
                                            identities of applicants and customers for digital financial 
                                            companies. 
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col me-4 my-4">
                                <div className="card">
                                    <div className="card-body">
                                        <i className="ri-layout-masonry-line"/>
                                        <h1>Schools & <br /> Aviations</h1>
                                        <p>
                                            Identitypass’s verification solutions ensure you’re dealing 
                                            with only the right individuals and eases the verification 
                                            processes providing accurate data. 
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col my-4">
                                <div className="card">
                                    <div className="card-body">
                                        <i className="ri-layout-masonry-line"/>
                                        <h1 style={{fontSize:"1.9rem"}}>E-commerce & <br /> Platforms</h1>
                                        <p>
                                            Our verification platform provides flexible & easy access to a 
                                            wide range of KYC products and services such as Biometrics verification 
                                            and documents oriented verifications ensuring customers’ privacy protection. 
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </ReactElasticCarousel>
                    </div>

                    
                    {/* displays only on mobile  */}
                    <div className="d-md-none">
                        <ReactElasticCarousel itemsToShow={1.05} enableAutoplay={true} >
                            <div className="col me-3 my-2">
                                <div className="card ">
                                    <div className="card-body">
                                        <i className="ri-bank-line"/>
                                        <h1>Crypto <br /> Companies</h1>
                                        <p>
                                            Our Biometric verification, crypto exchanges can get easy, 
                                            secure and law compliant customers’ verification. We combine 
                                            AI-based technology with the expertise of highly qualified 
                                            fraud-spotting specialists
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col me-3 my-2">
                                <div className="card ">
                                    <div className="card-body">
                                        <i className="ri-wallet-line"/>
                                        <h1>Fintechs & <br /> banks</h1>
                                        <p>
                                            Our layered approach using the identity document combined 
                                            with facial biometrics offers a more secure way to verify 
                                            identities of applicants and customers for digital financial 
                                            companies. 
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col me-3 my-2">
                                <div className="card">
                                    <div className="card-body">
                                        <i className="ri-layout-masonry-line"/>
                                        <h1>Schools & <br /> Aviations</h1>
                                        <p>
                                            Identitypass’s verification solutions ensure you’re dealing 
                                            with only the right individuals and eases the verification 
                                            processes providing accurate data. 
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col my-4">
                                <div className="card">
                                    <div className="card-body">
                                        <i className="ri-layout-masonry-line"/>
                                        <h1 style={{fontSize:"1.9rem"}}>E-commerce & <br /> Platforms</h1>
                                        <p>
                                            Our verification platform provides flexible & easy access to a 
                                            wide range of KYC products and services such as Biometrics verification 
                                            and documents oriented verifications ensuring customers’ privacy protection. 
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </ReactElasticCarousel>
                    </div>
                </div>
            </section>

            <Partners/> 
            
            {/* <Newsletter/> */}

            <Blogcard/>

        </div>
    )
}
