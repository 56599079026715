import React, { useState } from 'react'
import logo from '../../assets/logo.png'
import fingerprint from '../../assets/dropdown/fingerprint.svg'
import bubbleChat from '../../assets/dropdown/bubbleChat.svg'
import receipt from '../../assets/dropdown/receipt.svg'
import timeline from '../../assets/dropdown/timeline.svg'
import tabIcon from '../../assets/dropdown/tabIcon.svg'
import ballot from '../../assets/dropdown/ballot.svg'
import note from '../../assets/dropdown/note.svg'
import dashboard from '../../assets/dropdown/dashboard.svg'
import account from '../../assets/dropdown/account.svg'
import widget from '../../assets/dropdown/widget.svg'
import { Link } from 'react-router-dom'
import { Nav, Navbar } from 'react-bootstrap'

export default function NavbarArea() {
    const [productOptions, setProductOptions] = useState(false)
    const [solutionOptions, setSolutionOptions] = useState(false)
    const [careerOptions, setCareerOptions] = useState(false)

    let closeSolutionOption = ()=>{ 
        setSolutionOptions (!solutionOptions) 
        setCareerOptions (false) 
        setProductOptions (false) 
    }
    let closeProductOption = ()=>{ 
        setProductOptions (!productOptions) 
        setCareerOptions (false) 
        setSolutionOptions (false) 
    }
    let closeCareerOption = ()=>{ 
        setCareerOptions (!careerOptions) 
        setSolutionOptions (false) 
        setProductOptions (false) 
    }

    return (
        <>
            <div className="navbar-area fixed-top">
                <Navbar className="navbar-light"  expand="lg">
                    <div className="container ">
                        <Navbar.Brand href="/"><img src={logo}  alt=""/></Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="navbar-nav ms-auto py-2 mb-2 mb-lg-0 align-items-lg-center">
                                <li className="nav-item" onClick={closeSolutionOption} >
                                    <p className="nav-link" aria-current="page">Solutions</p>
                                    <i class="ri-arrow-down-s-line"/>

                                    { solutionOptions &&
                                    <div className="navbar-options products-options">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <Link to="/Mobility"  onClick={closeSolutionOption} className="link d-flex align-items-start ">
                                                    <img src={bubbleChat} alt="" />
                                                    <span>
                                                        <h6>Mobility</h6>
                                                        <p>Create a trusted ecosystem with verified drivers, and riders</p>
                                                    </span>
                                                </Link>
                                            </div>
                                            <div className="col-md-6 d-flex align-items-start">
                                                <Link to="/Financial-Services"  onClick={closeSolutionOption} className="link d-flex align-items-start ">
                                                    <img src={receipt} alt="" />
                                                    <span>
                                                        <h6>Financial Services</h6>
                                                        <p>Attain easy compliance without compromising security.</p>
                                                    </span>
                                                </Link>
                                            </div>
                                            <div className="col-md-6 d-flex align-items-start">
                                                <Link to="/Crypto-Exchanges"  onClick={closeSolutionOption} className="link d-flex align-items-start ">
                                                    <img src={tabIcon} alt="" />
                                                    <span>
                                                        <h6>Crypto Exchange</h6>
                                                        <p>Guarantee stable AML/KYC compliance and seamless customer onboarding.</p>
                                                    </span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                </li>
                                <li className="nav-item" onClick={closeProductOption} >
                                    <p className="nav-link" aria-current="page">Products</p>
                                    <i class="ri-arrow-down-s-line"/>

                                    { productOptions &&
                                    <div className="navbar-options products-options">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <Link to="/Identity-Verification" onClick={closeProductOption} className="link d-flex align-items-start">
                                                    <img src={fingerprint} alt="" />
                                                    <span>
                                                        <h6>Identity Verification</h6>
                                                        <p>Instantly verify identities online and boost your customer base.</p>
                                                    </span>
                                                </Link>
                                            </div>
                                            {/* <div className="col-md-6">
                                                <Link to="/Biometric-Authentication" onClick={closeNavOption} className="link d-flex align-items-start">
                                                    <img src={fingerprint} alt="" />
                                                    <span>
                                                        <h6>Biometric Authentication</h6>
                                                        <p>Create forms to collect verification data</p>
                                                    </span>
                                                </Link>
                                            </div> */}
                                            {/* <div className="col-md-6">
                                                <Link to="/Identity-Checker"  onClick={closeNavOption} className="link d-flex align-items-start ">
                                                    <img src={bubbleChat} alt="" />
                                                    <span>
                                                        <h6>IDChecker</h6>
                                                        <p>Verify businesses, individuals and docs.</p>
                                                    </span>
                                                </Link>
                                            </div>
                                            <div className="col-md-6 d-flex align-items-start">
                                                <img src={receipt} alt="" />
                                                <span>
                                                    <h6>IdentityForm</h6>
                                                    <p>Create forms to collect verification data</p>
                                                </span>
                                            </div> */}
                                            <div className="col-md-6 d-flex align-items-start">
                                                <Link to="/Identity-Graph"  onClick={closeProductOption} className="link d-flex align-items-start ">
                                                    <img src={timeline} alt="" />
                                                    <span>
                                                        <h6>Identity Graph</h6>
                                                        <p>Swiftly explore your customers' social relationships and connections.</p>
                                                    </span>
                                                </Link>
                                            </div>
                                            <div className="col-md-6 d-flex align-items-start">
                                                <Link to="/Identity-Radar"  onClick={closeProductOption} className="link d-flex align-items-start ">
                                                    <img src={tabIcon} alt="" />
                                                    <span>
                                                        <h6>Identity Radar</h6>
                                                        <p>Easily defend your business against identity fraud and cyber threats.</p>
                                                    </span>
                                                </Link>
                                            </div>
                                            {/* <div className="col-md-6 d-flex align-items-start">
                                                <img src={tabIcon} alt="" />
                                                <span>
                                                    <h6>MyPass</h6>
                                                    <p>Create forms to collect verification data</p>
                                                </span>
                                            </div> */}
                                        </div>
                                    </div>
                                    }
                                </li>
                                {/* <li className="nav-item" onClick={closeNavOption}>
                                    <p className="nav-link" >Resources</p>
                                    <i class="ri-arrow-down-s-line"/>

                                    { navOptions &&
                                    <div className="navbar-options resources-options">
                                        <div className="row">
                                            <div className="col-md-6 ">
                                                <Link to="/Blog" onClick={closeNavOption}  className="link d-flex align-items-start ">
                                                    <img src={ballot} alt="" />
                                                    <span>
                                                        <h6>Our Blog</h6>
                                                        <p>Get an insight to our mind</p>
                                                    </span>
                                                </Link>
                                            </div>
                                            <div className="col-md-6 d-flex align-items-start">
                                                <img src={note} alt="" />
                                                <span>
                                                    <h6>API Documentation</h6>
                                                    <p>Learn the know how of our APIs.</p>
                                                </span>
                                            </div>
                                            <div className="col-md-6 d-flex align-items-start">
                                                <img src={dashboard} alt="" />
                                                <span>
                                                    <h6>Status</h6>
                                                    <p>Stay up-to-date with the APIs </p>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                </li>
                                
                                <Link to="/Use-Cases" className="link ">
                                    <li className="nav-item">
                                        <p className="nav-link">Use Cases</p>
                                    </li>
                                </Link> */}

                                <li className="nav-item" onClick={closeCareerOption}>
                                    <p className="nav-link" href="#">Company</p>
                                    <i class="ri-arrow-down-s-line"/>

                                    { careerOptions &&
                                    <div className="navbar-options company-options">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <Link to="/About-Us" onClick={closeCareerOption}  className="link d-flex align-items-start ">
                                                    <img src={account} alt="" />
                                                    <span>
                                                        <h6>About Us</h6>
                                                        <p>Get an insight to our mind</p>
                                                    </span>
                                                </Link>
                                            </div>
                                            <div className="col-md-6 d-flex align-items-start">
                                                <Link to="/Career" onClick={closeCareerOption} className="link d-flex align-items-start ">
                                                    <img src={widget} alt="" />
                                                    <span>
                                                        <h6>Career</h6>
                                                        <p>Come work with us</p>
                                                    </span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                </li>
                                
                                <Link to="/Pricing" className="link ">
                                    <li className="nav-item">
                                        <p className="nav-link">Pricing</p>
                                    </li>
                                </Link>
                                
                                
                                <a className="link" 
                                    href="https://calendly.com/myidentitypay-demo/my-identity-pay-demo" 
                                    target="_blank" rel="noreferrer"
                                >
                                    <li className="nav-item">
                                        <p className="nav-link">Contact Sales</p>
                                    </li>
                                </a>
                                <a className="btn btn-purple px-4 py-2" href="https://dashboard.myidentitypass.com/signUp" target="_blank" rel="noreferrer">Get Started</a>
                            </Nav>
                        </Navbar.Collapse>
                    </div>
                </Navbar>
                {/* <div className="container" style={{position:"relative"}}> </div> */}
            </div>
        </>
    )
}
