import React from 'react'
import Blogcard from '../../components/blog-card'
import Faq from '../../components/faq'

export default function FAQPage
() {
  return (
    <div>
        <section className="career-hero">
            <div className="container">
                <div className="col-md-7 mx-auto">
                    <h1>FAQs</h1>
                    <p>
                        Checkout our frequently asked questions to get a clearer understanding about us.
                    </p>
                </div>
            </div>
        </section>

        <Faq/>
        <Blogcard/>

    </div>
  )
}
