import React, { useState } from 'react'
import { Accordion } from 'react-bootstrap'

export default function Faq() {
    const [activeFaq, setActiveFaq] = useState(0)
    return (
        <div>
            
            <section className="faq">
                <div className="container py-5">
                    <div className="col-md-8 mx-auto text-center">
                        <p>You’ve Got Questions? We Got Answers</p>
                        <h2>Frequently Asked Questions</h2>
                    </div>
                </div>
                <div style={{background:"#F4F5F7"}} className="py-5">
                    <div className="container">
                        <div className="col-md-8 mx-auto">
                            <Accordion>
                                    <div  className=" question-bg" onClick={()=>{setActiveFaq(0)}} >
                                        <p className=" question accordion-button" >
                                            What are the verification services offered by Identitypass?
                                        </p>
                                    </div>
                                    {activeFaq === 0 &&
                                        <div className="accordion-body">
                                            <p className="answer">
                                                We offer BVN, NIN, Voter's card verification, Phone number, Account 
                                                number, Driver's License, International passport for easy onboarding of customers.
                                            </p>
                                        </div>
                                    }
                                    <div  className=" question-bg" onClick={()=>{setActiveFaq(1)}}>
                                        <p className=" question accordion-button" >
                                        How long does Integration take?
                                        </p>
                                    </div>
                                    {activeFaq === 1 &&
                                        <div className="accordion-body">
                                            <p className="answer">
                                                We offer BVN, NIN, Voter's card verification, Phone number, Account 
                                                number, Driver's License, International passport for easy onboarding of customers.
                                            </p>
                                        </div>
                                    }
                                    <div  className=" question-bg" onClick={()=>{setActiveFaq(2)}}>
                                        <p className=" question accordion-button" >
                                        Do we get charged for failed API calls?
                                        </p>
                                    </div>
                                    {activeFaq === 2 &&
                                        <div className="accordion-body">
                                            <p className="answer">
                                                We offer BVN, NIN, Voter's card verification, Phone number, Account 
                                                number, Driver's License, International passport for easy onboarding of customers.
                                            </p>
                                        </div>
                                    }
                                    <div  className=" question-bg" onClick={()=>{setActiveFaq(3)}}>
                                        <p className=" question accordion-button" >
                                        How do we handle issues that may arise ?
                                        </p>
                                    </div>
                                    {activeFaq === 3 &&
                                        <div className="accordion-body">
                                            <p className="answer">
                                                We offer BVN, NIN, Voter's card verification, Phone number, Account 
                                                number, Driver's License, International passport for easy onboarding of customers.
                                            </p>
                                        </div>
                                    }
                                    <div  className=" question-bg" onClick={()=>{setActiveFaq(4)}}>
                                        <p className=" question accordion-button" >
                                        How do I integrate into any platform I build?
                                        </p>
                                    </div>
                                    {activeFaq === 4 &&
                                        <div className="accordion-body">
                                            <p className="answer">
                                                We offer BVN, NIN, Voter's card verification, Phone number, Account 
                                                number, Driver's License, International passport for easy onboarding of customers.
                                            </p>
                                        </div>
                                    }
                                    <div  className=" question-bg" onClick={()=>{setActiveFaq(5)}}>
                                        <p className=" question accordion-button" >
                                        Do you also provide Biometric verification services?
                                        </p>
                                    </div>
                                    {activeFaq === 5 &&
                                        <div className="accordion-body">
                                            <p className="answer">
                                                We offer BVN, NIN, Voter's card verification, Phone number, Account 
                                                number, Driver's License, International passport for easy onboarding of customers.
                                            </p>
                                        </div>
                                    }
                                    <div  className=" question-bg" onClick={()=>{setActiveFaq(6)}}>
                                        <p className=" question accordion-button" >
                                        How often do you experience system downtimes?
                                        </p>
                                    </div>
                                    {activeFaq === 6 &&
                                        <div className="accordion-body">
                                            <p className="answer">
                                                We offer BVN, NIN, Voter's card verification, Phone number, Account 
                                                number, Driver's License, International passport for easy onboarding of customers.
                                            </p>
                                        </div>
                                    }
                                    <div  className=" question-bg" onClick={()=>{setActiveFaq(7)}}>
                                        <p className=" question accordion-button" >
                                        How do we fund our wallet ?
                                        </p>
                                    </div>
                                    {activeFaq === 7 &&
                                        <div className="accordion-body">
                                            <p className="answer">
                                                We offer BVN, NIN, Voter's card verification, Phone number, Account 
                                                number, Driver's License, International passport for easy onboarding of customers.
                                            </p>
                                        </div>
                                    }
                                    <div  className=" question-bg" onClick={()=>{setActiveFaq(8)}}>
                                        <p className=" question accordion-button" >
                                        Is what you do the same as Smile ID?
                                        </p>
                                    </div>
                                    {activeFaq === 8 &&
                                        <div className="accordion-body">
                                            <p className="answer">
                                                We offer BVN, NIN, Voter's card verification, Phone number, Account 
                                                number, Driver's License, International passport for easy onboarding of customers.
                                            </p>
                                        </div>
                                    }
                            </Accordion>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
