import React from 'react'
import mission from '../../assets/about-us/mission.png'
import vision from '../../assets/about-us/vision.png'
import cardIcon from '../../assets/about-us/card-icon.png'
import one from '../../assets/about-us/one.png'
import two from '../../assets/about-us/two.png'
import three from '../../assets/about-us/three.png'
import four from '../../assets/about-us/four.png'
import five from '../../assets/about-us/five.png'
import six from '../../assets/about-us/six.png'
import seven from '../../assets/about-us/seven.png'
import eight from '../../assets/about-us/eight.png'
import nine from '../../assets/about-us/nine.png'
import ten from '../../assets/about-us/ten.png'
import eleven from '../../assets/about-us/eleven.png'
import twelve from '../../assets/about-us/twelve.png'
import people from '../../assets/about-us/people.png'

export default function AboutUs() {
    return (
        <div>
            <section className="about-us-hero text-md-center">
                <div className="container">
                    <div className="col-md-9 mx-auto">
                        <h1>We’re a complaince and security infrastructure company. </h1>
                        <p>
                            Today, the company provides user and company verification services 
                            across Nigeria.With multiple service offering, the company serve 
                            hundreds of brands in Fintech, insurance, Security, HR and many 
                            other sectors ensuring the brands are secured from ID thefts and 
                            also improving their customer onboarding (signup and verification) 
                            processes. Identitypass performs thousands of verifications daily for 
                            companies in Nigeria, US and other countries and also keeps developing 
                            smarter ways to ensure digital verification is easily, safe and secured.
                        </p>
                        <a href="#vision" className='link'>
                            <i className="ri-arrow-down-line" />
                        </a>
                        
                    </div>
                </div>
            </section>

            <section id='vision' className=" py-5">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6 mt-5">
                            <img src={mission} className="w-100" alt="" />
                        </div>
                        <div className="col-md-6 mt-5">
                            <h2>Our Vision</h2>
                            <p>
                                Our vision is backed by our values: Innovation, 
                                Transparency and Sustainability.  
                            </p>
                            <p>
                                These values represent our clear message that we are a solid and 
                                trusted infrastructure with a solid set of processes and tools 
                                to make precise decisions about who people and businesses are. 
                                Our profound vision is to promote trust and create a safe digital 
                                space where businesses deal only with the right people and can 
                                easily acquire honest individuals as customers and partners.  
                            </p>
                        </div>
                        <div className="col-md-6 mt-5">
                            <img src={vision} className="w-100" alt="" />
                        </div>
                        <div className="col-md-6 mt-5">
                            <h2>Our Mission & Plans</h2>
                            <p>
                                We are on a mission of making everyday life easier and secure 
                                for African businesses and individuals. We believe that we can 
                                cross some milestones when digital businesses in Africa can reliably 
                                and properly, secure their businesses and customers’ accounts from 
                                identity thefts and fraud.  
                            </p>
                            <p>
                                As a fast-growing African Verification company, we will continue 
                                to be consistent with the value of services we offer,  put our 
                                partners and customers first, making sure we never derail from 
                                our overarching vision  ‘To build a world class compliance and 
                                security infrastructure in Africa, free from identity theft and 
                                compliance bottlenecks”. 
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className=" core-values py-5">
                <div className="container">
                    <div className="col-md-6 mx-auto text-center py-5">
                        <p>
                            What we stand for
                        </p>
                        <h2>Our Core Values</h2>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-body">
                                    <img src={cardIcon} className="card-icon" alt="" />
                                    <h3>Innovation</h3>
                                    <p>We are a company driven by innovation, and our team member are innovative.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-body">
                                    <img src={cardIcon} className="card-icon" alt="" />
                                    <h3>Transparency</h3>
                                    <p>Our technology gives users and business transparency when handling their data.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-body">
                                    <img src={cardIcon} className="card-icon" alt="" />
                                    <h3>Sustainability</h3>
                                    <p>We build products and services that can scale, that is sustainable.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="team py-5">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <div className="row team-members">
                                    <div className="col-4">
                                        <img src={one} alt="" />
                                        <img src={four} alt="" />
                                        <img src={seven} alt="" />
                                        <img src={ten} alt="" />
                                    </div>
                                    <div className="col-4 mt-4">
                                        <img src={two} alt="" />
                                        <img src={five} alt="" />
                                        <img src={eight} alt="" />
                                        <img src={eleven} alt="" />
                                    </div>
                                    <div className="col-4">
                                        <img src={three} alt="" />
                                        <img src={six} alt="" />
                                        <img src={nine} alt="" />
                                        <img src={twelve} alt="" />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <p>Our Team</p>
                                <h1>We Have Fun, Create And Repeat</h1>
                                <p>
                                    The plan is to become the most customer-centric comapny; 
                                    bulild a community of strong teams achieving & fulfilling dreams 
                                    by building todays brand and tomorrow’s legacy. You think you have 
                                    what it takes to be a part of the team? Then you are doing well, 
                                    click on the button below to see how to be a part of the awe-mazing team
                                </p>
                                <a href='https://myidentitypass.bamboohr.com/jobs/' target="_blank" rel='noopener noreferrer' className="btn btn-purple px-4 py-3 mt-5 ">
                                    Become a team player
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-5">
                <div className="container py-5">
                    <div className="col-md-9 mx-auto p-3 p-md-5" style={{background: "#F4F5F7"}}>
                        <h2>Hear from the management</h2>
                        <p>
                            IdentityPass being a visionary technology product addressing concerns around 
                            digital security in the Africa digital space has been built with efficiency, 
                            security, great user experience, and in line with all required compliance frameworks. 
                            This product securely provides access to organizations to query requested users' 
                            information with the user's consent in place. Information includes access to both 
                            Government and Non-Government related data, financial data, and other data sources.
                        </p>
                        <p>
                            Integrating trust and compliance for millions of users in the Africa digital space 
                            is really challenging but it is a responsibility we have accepted even as we 
                            gradually rewrite the current narrative.
                        </p>
                        <h4 className="text-end">Signed Management</h4>
                    </div>

                    <div>
                        <div className="col-md-7 mx-auto text-center py-5 mt-5">
                            <h2>Come work with us</h2>
                            <p>
                                Love what you see? Come be a part of the humans doing a meaningful work 
                                with awe-mazing and super smart team mate who have your growth at heart
                            </p>
                            <a href='https://myidentitypass.bamboohr.com/jobs/' target="_blank" rel='noopener noreferrer' className="btn btn-purple px-4 py-3 mt-4 ">
                                Become a team player
                            </a>
                        </div>
                    </div>
                    <img src={people} className="w-100" alt="" />
                </div>
            </section>
        </div>
    )
}
