import React from 'react'
import credpalLogo from '../../assets/credpal-logo.png'
import mintLogo from '../../assets/mint-logo.png'
import GDCLogo from '../../assets/GDCLogo.png'
import teamaptLogo from '../../assets/teamapt.png'
import bundleLogo from '../../assets/Bundle-logo.png'
import pageLogo from '../../assets/pageLogo.png'
import fairmoneyLogo from '../../assets/fairmoneyLogo.png'
import carsLogo from '../../assets/cars45Logo.png'

export default function Partners() {
  return (
    <div>
        <section className='py-5'>
                <div className="container py-5">
                    <div className=" col-lg-8 mx-auto text-center">
                        {/* <h6>Partners and Customers</h6> */}
                        <h5>Customers and Partners Trusted By</h5>
                        <div className="row align-items-center justify-content-around py-3">
                            <div className="col-6 col-md-3 col-lg-3 mt-4">
                                <img src={credpalLogo} alt="" />
                            </div>
                            <div className="col-6 col-md-3 col-lg-3 mt-4">
                                <img src={mintLogo} alt="" />
                            </div>
                            <div className="col-6 col-md-3 col-lg-3 mt-4">
                                <img src={teamaptLogo} alt="" />
                            </div>
                            <div className="col-6 col-md-3 col-lg-3 mt-4">
                                <img src={carsLogo} alt="" />
                            </div>

                            <div className="col-6 col-md-3 col-lg-3 mt-4">
                                <img src={bundleLogo} alt="" />
                            </div>
                            <div className="col-6 col-md-3 col-lg-3 mt-4">
                                <img src={pageLogo} alt="" />
                            </div>
                            <div className="col-6 col-md-3 col-lg-3 mt-4">
                                <img src={fairmoneyLogo} alt="" />
                            </div>
                            <div className="col-6 col-md-3 col-lg-3 mt-4">
                                <img src={GDCLogo} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    </div>
  )
}
