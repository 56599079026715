import React from 'react'
import Blogcard from '../../components/blog-card'

export default function Pricing() {
    return (
        <div>
            <section className="py-5">
                <div className="container">
                    <div className="col-md-8 text-center mx-auto pt-5  pb-3">
                        <h1>Our Pricing</h1>
                        <p>
                            You only pay when make an API CALL! Start with free acount credit card obligation.
                        </p>
                    </div>
                </div>
            </section>

            <section  className="pricing-card py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-4 mt-3 p-md-0">
                            <div className="card">
                                <div className="card-body">
                                    <i class="ri-loader-4-line loader" />
                                    <h5>Standard Pricing</h5>
                                    <small>You can pay as you make your calls</small>
                                    <h3>$0.048</h3>
                                    <h6>Per Successful API Call</h6><hr />
                                    <div class="d-flex align-items-start">
                                        <p class="tick me-2">
                                            <i class="ri-check-line"></i>
                                        </p>
                                        <p> Free monthly credit.</p>
                                    </div>
                                    <div class="d-flex align-items-start">
                                        <p class="tick me-2">
                                            <i class="ri-check-line"></i>
                                        </p>
                                        <p>Access all APIs documentation and end points.</p>
                                    </div>
                                    <div class="d-flex align-items-start">
                                        <p class="tick me-2">
                                            <i class="ri-check-line"></i>
                                        </p>
                                        <p>Technical Support</p>
                                    </div>
                                    <button className="btn btn-purple py-3">
                                        Get Started
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-4 mt-3 p-md-0 blue">
                            <div className="card">
                                <div className="card-body">
                                    <div className="header">
                                        <i class="ri-loader-4-line loader" />
                                        <span>Recommended</span>
                                    </div>
                                    <h5>Premium Access</h5>
                                    <small>With premium plan comes with more access</small>
                                    <h3>Custom</h3>
                                    <h6>Seat and pick endpoint to access</h6><hr />
                                    <div class="d-flex align-items-start">
                                        <p class="tick me-2">
                                            <i class="ri-check-line"></i>
                                        </p>
                                        <p> Request for other biometrics needs for your business and we will get it Delivered.</p>
                                    </div>
                                    <button className="btn btn-white py-3">
                                        Get Started
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 mt-3 p-md-0">
                            <div className="card">
                                <div className="card-body">
                                    <div className="header">
                                        <i class="ri-loader-4-line loader" />
                                        <span>Recommended</span>
                                    </div>
                                    <h5>Enterprice Pricing</h5>
                                    <small>This pricing plan gives you every access to our APIs</small>
                                    <h3>Full Access</h3>
                                    <h6>Get full Access to all our Endpoints</h6><hr />
                                    <div class="d-flex align-items-start">
                                        <p class="tick me-2">
                                            <i class="ri-check-line"></i>
                                        </p>
                                        <p> Biometrics liveliness checker API.</p>
                                    </div>
                                    <div class="d-flex align-items-start">
                                        <p class="tick me-2">
                                            <i class="ri-check-line"></i>
                                        </p>
                                        <p>ID-Face Biometrics API.</p>
                                    </div>
                                    <div class="d-flex align-items-start">
                                        <p class="tick me-2">
                                            <i class="ri-check-line"></i>
                                        </p>
                                        <p>Authentication API.</p>
                                    </div>
                                    <div class="d-flex align-items-start">
                                        <p class="tick me-2">
                                            <i class="ri-check-line"></i>
                                        </p>
                                        <p>Verification API and many more.</p>
                                    </div>
                                    <button className="btn btn-purple py-3">
                                        Get Started
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Blogcard/>
            
        </div>
    )
}
