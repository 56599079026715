import React, { useState } from 'react'
// import IdCheckerHero from '../../assets/id-checker-hero.png'
import insight from '../../assets/moreGraph1.png'
import plug from '../../assets/id-graph/plug.png'
import ai from '../../assets/id-graph/ai.png'
// import widgetImg from '../../assets/id-checker/widget-img.png'
// import Newsletter from '../../components/newsletter'
import Blogcard from '../../components/blog-card'
import ReactElasticCarousel from 'react-elastic-carousel'
import Partners from '../../components/partners'


export default function IdGraph() {
    const [moreGraphPage, setMoreGraphPage] = useState(1)
    return (
        <div>
            <section className="id-checker-hero id-graph-hero py-5">
                <div className="container py-5">
                    <div className="row align-items-center">
                        <div className="col-lg-7 py-5">
                            <h1>Identity Graph</h1>
                            <p>
                                Screen customers against global watchlists. Reduce risk and easily 
                                comply with AML/KYC regulations. Understand your customers by assessing 
                                their connections to several other data points across the globe.
                            </p>
                            <a href='https://calendly.com/myidentitypay-demo/my-identity-pay-demo' target="_blank" rel='noopener noreferrer' className="btn btn-white px-5 py-3 mt-3">Talk to Sales</a>
                        </div>
                    </div>
                </div>
            </section>
            
            <section className="id-checker-channels py-md-5">
                <div className="container">
                    <div className="col-md-6 mx-auto text-md-center py-5">
                        <p>
                            Get all the juice in few steps
                        </p>
                        <h2>Subset of Products</h2>
                    </div>

                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <img src={insight} alt="" />
                        </div>
                        <div className="col-md-6">
                            <div className="col-md-12 col-lg-10 my-4 mx-auto ">
                                <h3> Deep Relationship insight infrastructure</h3>
                                <p className="my-4">
                                    Easily determine relationships, people, social media, and any 
                                    digital footprints linked to your customers or any identity using 
                                    Identitypass's vast digital infrastructure.
                                </p>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="d-flex align-items-center">
                                            <p class="tick me-2">
                                                <i class="ri-check-line"></i>
                                            </p>
                                            <p>Discover social connections</p>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <p className="tick me-2">
                                                <i className="ri-check-line"></i>
                                            </p>
                                            <p>Detect past fraud patterns</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="d-flex align-items-center">
                                            <p className="tick me-2">
                                                <i className="ri-check-line"></i>
                                            </p>
                                            <p>Social media screening</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-md-6 order-md-2">
                            <img src={plug} alt="" />
                        </div>
                        <div className="col-md-6 order-md-1">
                            <div className="col-md-12 col-lg-10 my-4 mx-auto">
                                <h3>Simple Plug-ins</h3>
                                <p className="my-4">
                                    With Identity Graph, you can easily plug in an existing dataset ranging 
                                    from different industries(Fin-tech, Transportation, health, and many more 
                                    for custom data sources to gain further deeper insights
                                </p>
                                <div class="row">
                                    <div class="col-md-5">
                                        <div class="d-flex align-items-center">
                                            <p class="tick me-2">
                                                <i class="ri-check-line"></i>
                                            </p>
                                            <p>No-code tools </p>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <p class="tick me-2">
                                                <i class="ri-check-line"></i>
                                            </p>
                                            <p>Graph Portal</p>
                                        </div>
                                    </div>
                                    <div class="col-md-7">
                                        <div class="d-flex align-items-center">
                                            <p class="tick me-2">
                                                <i class="ri-check-line"></i>
                                            </p>
                                            <p>Multi-channel integration options</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <img src={ai} alt="" />
                        </div>
                        <div className="col-md-6">
                            <div className="col-md-12 col-lg-10 my-4 mx-auto">
                                <h3>AI-powered analytics</h3>
                                <p className="my-4">
                                    With a data set mapped to an identity(individual or organization), Identity 
                                    graphs provide reasonable insights and analytics into an individual’s/business 
                                    connection with other data sets/sources using trained machine learning models. 
                                </p>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="d-flex align-items-center">
                                            <p class="tick me-2">
                                                <i class="ri-check-line"></i>
                                            </p>
                                            <p> Data Importation and analysis</p>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <p class="tick me-2">
                                                <i class="ri-check-line"></i>
                                            </p>
                                            <p>Open-source data integrations</p>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="d-flex align-items-center">
                                            <p class="tick me-2">
                                                <i class="ri-check-line"></i>
                                            </p>
                                            <p>Data mining</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="more-identity-graph-area py-5" style={{backgroundColor:"#F7EDFC"}} >
                <div className="container">

                    <div className="row ">
                        <div className="col-md-6">
                            <h5>GET THE GIST</h5>
                            <h2>Seamless Integration</h2>
                            <p>
                                Knowing the risks associated with an identity enables you to effectively 
                                protect your business. Easily determine the risks associated with identities 
                                right from onboarding by leveraging the flexibility that accompanies integrating 
                                Identitypass's APIs into your system.
                            </p>
                        </div>
                            
                        <div className="col-md-6 ps-lg-5">
                            {moreGraphPage === 1 ? 
                                <>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="tick me-3">
                                                <i className="ri-check-line"></i>
                                            </div>
                                            <div>
                                                <h5>Get the ball rolling with our SDK</h5>
                                                <p>
                                                    Add an extra layer of verification to your website 
                                                    through our SDKs. Confirm your customers are truly who 
                                                    they claim to be using Identity verification solutions 
                                                    integrated into your existing verification system/tech 
                                                    stack with just a few steps. This keeps fraudsters out 
                                                    while real customers are onboarded in seconds.
                                                </p>
                                                <a href="go" target="_blank" rel="noreferrer noopener" > Get Started</a>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='inactive d-flex align-items-center px-md-4 pt-5' onClick={()=> setMoreGraphPage(2)} >
                                        <i className="ri-pulse-line me-3" />
                                        <h5>Integrate with our APIs</h5>
                                    </div><hr />
                                    <div className='inactive d-flex align-items-center p-4' onClick={()=> setMoreGraphPage(3)}>
                                        <i className="ri-qr-scan-2-line me-3"/>
                                        <h5>Not a Dev? Enjoy our No-Code Integrations</h5>
                                    </div><hr />
                                    <div className='inactive d-flex align-items-center p-4' onClick={()=> setMoreGraphPage(4)}>
                                        <i className="ri-alert-line me-3"/>
                                        <h5>Play with our Portal Directly</h5>
                                    </div><hr />
                                </>
                                :
                                moreGraphPage === 2 ? 
                                <>
                                    <div className='inactive d-flex align-items-center px-4 pt-5' onClick={()=>setMoreGraphPage(1)} >
                                        <i className="ri-checkbox-circle-line ri-xl me-3" />
                                        <h5>Get the ball rolling with our SDK</h5>
                                    </div><hr />
                                    <div className="card">
                                        <div className="card-body">
                                            <i class="ri-pulse-line ri-xl me-3 mt-2" style={{color:"#1633A3"}} />
                                            <div>
                                                <h5>Integrate with our APIs</h5>
                                                <p>
                                                    You can take productivity and automation to the next level with any of our APIs
                                                </p>
                                                <a href="go" target="_blank" rel="noreferrer noopener" > Get Started</a>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='inactive d-flex align-items-center p-4' onClick={()=> setMoreGraphPage(3)}>
                                        <i className="ri-qr-scan-2-line me-3"/>
                                        <h5>Not a Dev? Enjoy our No-Code Integrations</h5>
                                    </div><hr />
                                    <div className='inactive d-flex align-items-center p-4' onClick={()=> setMoreGraphPage(4)}>
                                        <i className="ri-alert-line me-3"/>
                                        <h5>Play with our Portal Directly</h5>
                                    </div><hr />
                                </>
                                :
                                moreGraphPage === 3 ? 
                                <>
                                    
                                    <div className='inactive d-flex align-items-center px-4 pt-5' onClick={()=>setMoreGraphPage(1)} >
                                        <i className="ri-checkbox-circle-line ri-xl me-3" />
                                        <h5>Get the ball rolling with our SDK</h5>
                                    </div><hr />

                                    <div className='inactive d-flex align-items-center px-md-4 pt-5' onClick={()=> setMoreGraphPage(2)} >
                                        <i className="ri-pulse-line me-3" />
                                        <h5>Integrate with our APIs</h5>
                                    </div><hr />
                                    
                                    <div className="card">
                                        <div className="card-body">
                                            <i class="ri-pulse-line ri-xl me-3 mt-2" style={{color:"#1633A3"}} />
                                            <div>
                                                <h5>Not a Dev? Enjoy our No-Code Integrations</h5>
                                                <p>
                                                    Create a customized Workflow that replicates your brand identity and 
                                                    blends with your verification needs through the identity checker widget.
                                                </p>
                                                <a href="go" target="_blank" rel="noreferrer noopener" > Get Started</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='inactive d-flex align-items-center p-4' onClick={()=> setMoreGraphPage(4)}>
                                        <i className="ri-alert-line me-3"/>
                                        <h5>Play with our Portal Directly</h5>
                                    </div><hr />
                                </>
                                :
                                <>
                                    <div className='inactive d-flex align-items-center px-4 pt-5' onClick={()=>setMoreGraphPage(1)} >
                                        <i className="ri-checkbox-circle-line ri-xl me-3" />
                                        <h5>Get the ball rolling with our SDK</h5>
                                    </div><hr />
                                    <div className='inactive d-flex align-items-center px-md-4 pt-5' onClick={()=> setMoreGraphPage(2)} >
                                        <i className="ri-pulse-line me-3" />
                                        <h5>Integrate with our APIs</h5>
                                    </div><hr />
                                    <div className='inactive d-flex align-items-center p-4' onClick={()=> setMoreGraphPage(3)}>
                                        <i className="ri-qr-scan-2-line me-3"/>
                                        <h5>Not a Dev? Enjoy our No-Code Integrations</h5>
                                    </div><hr />
                                    <div className="card">
                                        <div className="card-body">
                                            <i class="ri-pulse-line ri-xl me-3 mt-2" style={{color:"#1633A3"}} />
                                            <div>
                                                <h5>Not a Dev? Enjoy our No-Code Integrations</h5>
                                                <p>
                                                    Create a customized Workflow that replicates your brand identity and 
                                                    blends with your verification needs through the identity checker widget.
                                                </p>
                                                <a href="go" target="_blank" rel="noreferrer noopener" > Get Started</a>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </section>

            <section className="ideal-customers every-industry-area py-5">
                <div className="container">
                    <div className="col-md-6 mx-auto text-center py-5">
                        <h2>Ideal Customers</h2>
                        <p>From Local to Global Organisations, all you have to do is integrate our APIs into your System.</p>
                    </div>
                    <div className="row d-none d-lg-flex">
                        <div className="col-md-3 mt-5">
                            <div className="card ">
                                <div className="card-body">
                                    <i className="ri-bank-line"/>
                                    <h1>Fintech <br /> Companies</h1>
                                    <p>
                                        Adopting AI and machine learning, we offer a fully automated 
                                        social connections discovery product that makes it easier for 
                                        Fintechs to track a customer's relationship and transactions 
                                        relating to past financial crimes. This will hasten your decisions 
                                        and swiftly prevent your business from fraud.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mt-5">
                            <div className="card ">
                                <div className="card-body">
                                    <i className="ri-wallet-line"/>
                                    <h1>Investment <br /> Platforms</h1>
                                    <p>
                                        With Identity graph, you can quickly detect customers with past records 
                                        of impersonation and financial fraud engagements. This will enable you 
                                        to keep them out from your platform and protect other legal customers 
                                        during onboarding. 
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mt-5">
                            <div className="card">
                                <div className="card-body">
                                    <i className="ri-layout-masonry-line"/>
                                    <h1>Mobility <br /> Sector</h1>
                                    <p>
                                        Tracking a driver's connection to other illegal agencies just got easier 
                                        with Identity Graph. You can now easily spot a driver's past 
                                        irregularities and employers to make your decision before onboarding 
                                        him into your company. 
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mt-5">
                            <div className="card">
                                <div className="card-body">
                                    <i className="ri-layout-masonry-line"/>
                                    <h1 style={{fontSize:"1.9rem"}}>Human Resource <br /> Management companies</h1>
                                    <p>
                                        You can ascertain the performance history of an employee from records 
                                        of past employment to determine if he/she is the right fit for an agency.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* displays only on tablet  */}
                    <div className="d-none d-md-block d-lg-none">
                        <ReactElasticCarousel itemsToShow={2.0} enableAutoplay={true} >
                            <div className="col me-4 my-4">
                                <div className="card ">
                                    <div className="card-body">
                                        <i className="ri-bank-line"/>
                                        <h1>Crypto <br /> Companies</h1>
                                        <p>
                                            Our Biometric verification, crypto exchanges can get easy, 
                                            secure and law compliant customers’ verification. We combine 
                                            AI-based technology with the expertise of highly qualified 
                                            fraud-spotting specialists
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col me-4 my-4">
                                <div className="card ">
                                    <div className="card-body">
                                        <i className="ri-wallet-line"/>
                                        <h1>Fintechs & <br /> banks</h1>
                                        <p>
                                            Our layered approach using the identity document combined 
                                            with facial biometrics offers a more secure way to verify 
                                            identities of applicants and customers for digital financial 
                                            companies. 
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col me-4 my-4">
                                <div className="card">
                                    <div className="card-body">
                                        <i className="ri-layout-masonry-line"/>
                                        <h1>Schools & <br /> Aviations</h1>
                                        <p>
                                            Identitypass’s verification solutions ensure you’re dealing 
                                            with only the right individuals and eases the verification 
                                            processes providing accurate data. 
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col my-4">
                                <div className="card">
                                    <div className="card-body">
                                        <i className="ri-layout-masonry-line"/>
                                        <h1 style={{fontSize:"1.9rem"}}>E-commerce & <br /> Platforms</h1>
                                        <p>
                                            Our verification platform provides flexible & easy access to a 
                                            wide range of KYC products and services such as Biometrics verification 
                                            and documents oriented verifications ensuring customers’ privacy protection. 
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </ReactElasticCarousel>
                    </div>

                    
                    {/* displays only on mobile  */}
                    <div className="d-md-none">
                        <ReactElasticCarousel itemsToShow={1.05} enableAutoplay={true} >
                            <div className="col me-3 my-2">
                                <div className="card ">
                                    <div className="card-body">
                                        <i className="ri-bank-line"/>
                                        <h1>Crypto <br /> Companies</h1>
                                        <p>
                                            Our Biometric verification, crypto exchanges can get easy, 
                                            secure and law compliant customers’ verification. We combine 
                                            AI-based technology with the expertise of highly qualified 
                                            fraud-spotting specialists
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col me-3 my-2">
                                <div className="card ">
                                    <div className="card-body">
                                        <i className="ri-wallet-line"/>
                                        <h1>Fintechs & <br /> banks</h1>
                                        <p>
                                            Our layered approach using the identity document combined 
                                            with facial biometrics offers a more secure way to verify 
                                            identities of applicants and customers for digital financial 
                                            companies. 
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col me-3 my-2">
                                <div className="card">
                                    <div className="card-body">
                                        <i className="ri-layout-masonry-line"/>
                                        <h1>Schools & <br /> Aviations</h1>
                                        <p>
                                            Identitypass’s verification solutions ensure you’re dealing 
                                            with only the right individuals and eases the verification 
                                            processes providing accurate data. 
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col my-4">
                                <div className="card">
                                    <div className="card-body">
                                        <i className="ri-layout-masonry-line"/>
                                        <h1 style={{fontSize:"1.9rem"}}>E-commerce & <br /> Platforms</h1>
                                        <p>
                                            Our verification platform provides flexible & easy access to a 
                                            wide range of KYC products and services such as Biometrics verification 
                                            and documents oriented verifications ensuring customers’ privacy protection. 
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </ReactElasticCarousel>
                    </div>
                </div>
            </section>

            
            <Partners/>

            {/* <Newsletter/> */}

            <Blogcard/>

        </div>
    )
}
